import {mdiArrowLeftBoldCircleOutline} from "@mdi/js";
import Icon from "@mdi/react";
import React, {useEffect, useState} from "react";
import {Accordion, Breadcrumb, Card, OverlayTrigger, Tooltip, ListGroup, Button, Row, Col} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {smartshapeConfig} from "../../config";
import {Ppv3d as Ppv3dService} from "../../services/Ppv3d";
import {Smartshape as SmartshapeService} from "../../services/Smartshape";
import {store} from "../../store";
import {SearchActions} from "../../store/redux/actions";
import {SEARCH} from "../../store/redux/constants";
import {ISearch} from "../../store/redux/types";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";
import EquipementDetail from "./equipementDetail";
import {SzIcon} from "react-theme-components";
import {categories, mapType3D} from "../../config/constants";

import {
    clearItvGraphics,
    highlightObjects,
    highlightObjectsScene,
    mapLoaderConfig,
} from "../../manager/map/loader";
import {render} from "enzyme";
import {Token as TokenService} from "../../services/Token";
import errorMessageDynamicMessage from "../Utils/messages/errorMessageDynamicMessage";
import ErrorMessageDynamicMessage from "../Utils/messages/errorMessageDynamicMessage";
import Request from "../../services/Request";

declare global {
    interface Window {
        Minko: any;
    }
}

interface IWork3dProps {
    ppv3dData?: any;
    ifcFileId?: any;
    meshList?: any;
    feature?: any;
    shownEquipementId?: string;
    displayEquipementFromSmartshapePlayer: (equipementId: string) => void;
    search?: ISearch;
    displaySmartshape?: any;
    displaySmartshapeSessionError?: any;
    showDetail?: any;
    OnlyHighlightObjectsSig?: any;
    showSmartshape?: any


}

const Work3d = (props: IWork3dProps) => {

    const {t} = useTranslation();
    const [equipement, setEquipment] = useState<any>(null);
    const [equipementId, setEquipmentId] = useState<any>(null);
    const [showEquipement, setShowEquipment] = useState(false);
    const [ppv3DOpen, setPpv3DOpen] = useState<boolean>(true);
    const [meshOpen, setMeshOpen] = useState(-1);


    let smartshapeDomain = "";
    if (smartshapeConfig.host) {
        const domainRegex = /^(https?:\/\/[^\/]+)\//;
        const matches = smartshapeConfig.host.match(domainRegex);
        if (matches && matches.length > 1) {
            smartshapeDomain = matches[1];
            console.log("smartshapeDomain", smartshapeDomain);

        }
    } else {
        console.error("smartshapeConfig.host is undefined");
    }


    const equipementDetail = (equipmentId: string, fromList: boolean) => {
        store.dispatch({payload: {flag: {loading: true}}, type: SEARCH.LOADING});
        console.log('equipmentId: ', equipmentId)
        return Ppv3dService.getEquipement(equipmentId)
            .then((data: any) => {
                setEquipment(data);
                setEquipmentId(equipmentId);
                props.displayEquipementFromSmartshapePlayer(equipmentId);
                const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
                if (props.ifcFileId && fromList && smartshapeDomain != "" && iframe) {
                    const jsonwebtoken = TokenService.getCookie("jsonwebtoken") ?? "";
                    const XSmartShapeSession = TokenService.getCookie("X-SmartShape-Session") ?? "";

                    return SmartshapeService.getNodesByEquipement(props.ifcFileId, equipmentId, jsonwebtoken, XSmartShapeSession)
                        .then((response: any) => {
                            console.log('getNodesByEquipement response: ', response)
                            if (response) {
                                let nodes: string[] = [];
                                response.forEach((node: any, index: any) => {
                                    nodes.push(node.id)
                                });
                                if (nodes.length>0) {
                                    console.log("nodes: ", nodes);
                                    if (iframe.contentWindow) {
                                        let focusNodeMessage = {
                                            "type": "patchState",
                                            "data": [
                                                {
                                                    "op": "replace",
                                                    "path": "/scene/focusedNode/id",
                                                    "value": nodes[0]
                                                }
                                            ]
                                        };
                                        iframe.contentWindow!.postMessage(JSON.stringify(focusNodeMessage), smartshapeDomain);
                                        console.log('focusNodeMessage: ', focusNodeMessage)

                                        let nodesObject: any = {};
                                        nodesObject[nodes[0]] = {};
                                        let selectedNodesMessage = {
                                            "type": "patchState",
                                            "data": [
                                                {
                                                    "op": "replace",
                                                    "path": "/selectedNodes",
                                                    "value": {
                                                        "nodes": nodesObject,
                                                        // The timestamp must be specified.
                                                        "timestamp": Date.now()
                                                    }
                                                },
                                            ]
                                        };
                                        console.log('selectedNodesMessage: ', selectedNodesMessage)
                                        iframe.contentWindow!.postMessage(JSON.stringify(selectedNodesMessage), smartshapeDomain);
                                    }
                                }

                            }
                        }).then(() => store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING}));
                } else {
                    store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
                }

            }).catch((error: Error) => {
                store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
                console.log(error);
            });
    };

    const focusEquipementintoPlayer = (selectedNode: string) => {
        console.log("selectedNode: ", selectedNode);
        store.dispatch({payload: {flag: {loading: true}}, type: SEARCH.LOADING});
        if (props.ifcFileId) {
            console.log('from player')
            const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
            if (iframe.contentWindow) {
                let nodesObject: any = {};
                nodesObject[selectedNode] = {};

                let focusNodeMessage = {
                    "type": "patchState",
                    "data": [
                        {
                            "op": "replace",
                            "path": "/scene/focusedNode/id",
                            "value": selectedNode
                        }
                    ]
                };
                iframe.contentWindow!.postMessage(JSON.stringify(focusNodeMessage), smartshapeDomain);
                console.log('focusNodeMessage: ', focusNodeMessage)

                let selectedNodesMessage = {
                    "type": "patchState",
                    "data": [
                        {
                            "op": "replace",
                            "path": "/selectedNodes",
                            "value": {
                                "nodes": nodesObject,
                                // The timestamp must be specified.
                                "timestamp": Date.now()
                            }
                        },
                    ]
                };
                console.log('selectedNodesMessage: ', selectedNodesMessage)
                iframe.contentWindow!.postMessage(JSON.stringify(selectedNodesMessage), smartshapeDomain);

            }
        }
        store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
    };


    useEffect(() => {
        if (equipement) {
            setShowEquipment(true);
        }
    }, [equipement]);

    /*    useEffect(() => {
            console.log("call equipementDetail on useEffect",props.shownEquipementId,equipementId)
            if (props.shownEquipementId && props.shownEquipementId !== equipementId) {
                equipementDetail(props.shownEquipementId, true);
            }
        }, [props.shownEquipementId]);*/


    const showIfc = async (shown3dObjectOnSmartshapeIframe?: any, tronconList?: any) => {
        let showSmartshape = false;

        const jsonwebtoken = TokenService.getCookie("jsonwebtoken") ?? "";
        const XSmartShapeSession = TokenService.getCookie("X-SmartShape-Session") ?? null;
        console.log("XSmartShapeSessiontoken",XSmartShapeSession)
        console.log("jsonwebtoken",jsonwebtoken)

        const res = await fetch(smartshapeDomain+'/account/session', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jsonwebtoken}`,
            },
        });
        console.log('response smartshape get session token',res)
        if (!res.ok && res.status==402) {
            props.displaySmartshapeSessionError({smartshapeSessionError:true});
            return
        }

        //const data = await res.json();
        //console.log('response get session',JSON.stringify(data, null, 2));


        //if (XSmartShapeSession) {
            console.log("XSmartShapeSession valide")
            if ((props.feature["3dnetwork:3d_mesh_available"] || props.feature["3dstrucuture:3d_ifc_available"]) && shown3dObjectOnSmartshapeIframe && props.search) {

                if (props.feature["3dnetwork:3d_mesh_available"] || props.feature["3dstrucuture:3d_ifc_available"]) {
                    showSmartshape = props.feature["3dnetwork:3d_mesh_available"] || props.feature["3dstrucuture:3d_ifc_available"];
                    shown3dObjectOnSmartshapeIframe = shown3dObjectOnSmartshapeIframe;
                } else {
                    showSmartshape = false;
                    shown3dObjectOnSmartshapeIframe = {}
                }

                if (tronconList && props.search) {
                    tronconList.map((troncon: any, index: any) => {
                        if (props.search && "3dnetwork:troncon:sig:couche" in troncon && "3dnetwork:troncon:index:name" in troncon) {
                            props.search.detail.shownDetailCategory = categories.ASSET;
                            let layer_id = troncon["3dnetwork:troncon:sig:couche"] + '--' + troncon["3dnetwork:troncon:asset:id_sig"];
                            let tronconIndexName = troncon["3dnetwork:troncon:index:name"] + "";
                            props.search.detail.shownDetailId = layer_id

                            props.OnlyHighlightObjectsSig(layer_id, props.search, tronconIndexName);
                        }
                    })
                }
                props.displaySmartshape({showSmartshape, shown3dObjectOnSmartshapeIframe});
            }

        // } else {
        //     console.log("XSmartShapeSession not valid")
        //     props.displaySmartshapeSessionError({smartshapeSessionError:true});
        //
        // }
    };

    //ici on reste a l'ecoute du smartshape, si un node est selectionné on appelle smartShapeMessageHandler
    useEffect(() => {
        if (props.ifcFileId && props.showSmartshape == true) {
            window.removeEventListener("message", smartShapeMessageHandler);
            const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
            if (iframe) {
                iframe.onload = () => {
                    // Listen to messages coming from SmartShape.
                    window.addEventListener("message", smartShapeMessageHandler, false);
                    // Send an empty message to the player so the player knows it needs to answer the parent document.
                    if (iframe.contentWindow) {
                        window.setTimeout(() => {
                            iframe.contentWindow!.postMessage('[]', smartshapeDomain);
                        }, 5000);
                    }
                }
            }
        } else {
            console.log("non props.showSmartshape ", props.showSmartshape)
        }
        return () => {
            window.removeEventListener("message", smartShapeMessageHandler);
        };
    }, [props.ifcFileId, props.showSmartshape]);

    // calling only when select object on player
    //besoin de chercher l'id de l'equipement from smartshape to get detail from ppv3d
    const smartShapeMessageHandler = (event: any) => {
        if (!event || !event.data || event.origin != smartshapeDomain)
            return;

        const message = JSON.parse(event.data);
        if (message[0] === 'updateState' && Array.isArray(message[1]) && message[1].length > 0 && message[1][0].path === '/selectedNodes') {
            const selectedNodes = Object.keys(message[1][0].value.nodes);

            const jsonwebtoken = TokenService.getCookie("jsonwebtoken") ?? "";
            const XSmartShapeSession = TokenService.getCookie("X-SmartShape-Session") ?? "";

            if (selectedNodes.length > 0) {
                console.log('Selected node on player: ', selectedNodes);
                const firstSelectedNodes = selectedNodes[0].split(";");

                //get equipement id by selected node id on player
                SmartshapeService.getNodesById(props.ifcFileId, firstSelectedNodes[0], jsonwebtoken, XSmartShapeSession)
                    .then((response: any) => {
                        console.log('response get equipement with selected nodeId on player: ', response.attributes.attributes)
                        if (response.attributes) {
                            const attributes = Object.values(response.attributes.attributes);
                            if (Array.isArray(attributes)) {
                                const result = attributes.filter(
                                    (attribute: any) => attribute.key === "CodEquip",
                                );
                                if (result[0]) {
                                    focusEquipementintoPlayer(firstSelectedNodes[0]);
                                    equipementDetail(result[0].value, false);
                                } else {
                                    props.displayEquipementFromSmartshapePlayer("");
                                }
                            } else {
                                console.error("response.attributes.attributes n'est pas un tableau ou n'est pas défini correctement.");
                                handleBreadcrumbClick();
                            }
                        }
                    }).catch((error: Error) => {
                    console.log(error);
                });

            } else {
                console.log('No node selected.')
                handleBreadcrumbClick();
            }
        }
    }
    function formatSigType(sigType:any) {
        return sigType.replace(/^asset:/, '').replace(/_/g, ' ');
    }

    function getValidNum(nomExmpl:any, idSig:any) {
        return (!nomExmpl || nomExmpl.trim() === "" || nomExmpl === "NON CONCERNE") ? idSig : nomExmpl;
    }



    const handleBreadcrumbClick = (): void => {
        setEquipment(null);
        setShowEquipment(false);
        setTimeout(() => {
            const elements = document.getElementsByClassName("active");
            for (const element of elements as any) {
                element.classList.remove("selected");
            }
            const element = document.getElementsByClassName(`equipementId-${equipementId}`)[0];
            if (element) {
                element.classList.add("active");
            } else {
                console.error("Element not found or undefined");
            }

        }, 1000);
        setEquipmentId(null);
        props.displayEquipementFromSmartshapePlayer("");
    };

    let ppv3dExist = false
    let meshExist = false
    let ppv3dDataBlock = {}
    let meshDataBlock = {}
    if (props.ppv3dData && props.ppv3dData.tagLabel) {
        ppv3dExist = true
        ppv3dDataBlock = (
            <Accordion defaultActiveKey="0">
                <Card className="container-bordered">
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setPpv3DOpen(!ppv3DOpen)}>
                        <strong>{t("asset:3d.work3d")}</strong>
                        {ppv3DOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                            <SzIcon variant="bold" icon="arrow-down-1"/>
                        }
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="work3d">
                                <ul>
                                    <li className={`gaia-search-data__item`}>
                                            <span
                                                className="gaia-search-data__item-content">{t("asset:3d.model3d") + props.ppv3dData.tagLabel.substring(props.ppv3dData.tagLabel.indexOf("G-PS"), props.ppv3dData.tagLabel.length)}</span>
                                        {props.feature["3dstrucuture:3d_ifc_list"] ?
                                            <div className="gaia-search-data__item-links">
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{show: 100, hide: 200}}
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            <Trans>visualiser</Trans>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i
                                                        className="gaia-search-data__item-links-icon material-icons clickable material-icons-24px"
                                                        onClick={() => showIfc(props.feature["3dstrucuture:3d_ifc_list"][0])}
                                                    >remove_red_eye</i>
                                                </OverlayTrigger>
                                                <br></br>
                                            </div> : <></>}
                                    </li>
                                </ul>
                            </div>
                            <div className="work3d">
                                {(showEquipement && equipement) ?
                                    (
                                        <>
                                            <Breadcrumb className={"gaia_breadcrumbs"}>
                                                <Breadcrumb.Item
                                                    className={"gaia_breadcrumb search_keywords clickable"}
                                                    onClick={() => handleBreadcrumbClick()}
                                                >
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{show: 100, hide: 200}}
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {t("back_to_work")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Icon path={mdiArrowLeftBoldCircleOutline} size={1}/>
                                                    </OverlayTrigger>
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    className={"gaia_breadcrumb search_keywords clickable"}
                                                    onClick={() => handleBreadcrumbClick()}
                                                >
                                                    {props.ppv3dData.tagLabel}
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    className={"gaia_breadcrumb estate_object_name"}>
                                                    {equipement.tag} - {equipement.tagLabel}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                            <EquipementDetail shownItem={equipement}/>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <h3 className="gaia-detail-result--header--primary-title primary-title-category">
                                                {t("asset:explore_3d_work")}
                                            </h3>
                                            <ul>
                                                <li>{props.ppv3dData.tagLabel} - {props.ppv3dData.tag}
                                                    <ul>
                                                        {props.ppv3dData["Related Items"]["est le site de"].map(
                                                            (e: any, key: number) => {
                                                                if (e.TagLabel != "Migration" && e.TagLabel != "Général Site") {
                                                                    return (
                                                                        <li key={key}
                                                                            className={`gaia-search-data__item equipementId-${e.Name}`}>
                                                                            <span
                                                                                className="gaia-search-data__item-content">{e.TagLabel} - {e.Name}</span>
                                                                            <div
                                                                                className="gaia-search-data__item-links">
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    delay={{show: 100, hide: 200}}
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            <Trans>open_details</Trans>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className="gaia-search-data__item-links-icon material-icons clickable"
                                                                                        onClick={() => equipementDetail(e.Name, true)}
                                                                                    >format_list_bulleted</i>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                                ;
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                        </>
                                    )}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        );

    }
    if (props.meshList
        && Array.isArray(props.meshList)
        // && (props.feature["index:name"].toUpperCase().includes("TRONCON") || props.feature["index:name"].toUpperCase().includes("REGARD_DE_VISITE"))
    ) {
        meshExist = true
        meshDataBlock = (
            <>
                {props.meshList && props.meshList.map((meshListFiled, index) => {

                    let meshFilePathToArray = meshListFiled["3dnetwork:3d_mesh_file_path"].split('/');
                    let regardAmontAval = meshFilePathToArray[2].split('-');
                    let firstRegard = regardAmontAval[0];
                    let secondRegard = regardAmontAval[1];

                    interface tronconInterface {
                        "3dnetwork:folder_name": string;
                        "3dnetwork:troncon:sig:type": string;
                        "3dnetwork:troncon:asset:id_sig": string;
                        "3dnetwork:troncon:sig:couche": string;
                        "3dnetwork:troncon:sig:adressecomplete": string;
                        "3dnetwork:troncon:index:name": string;
                    }

                    let tronconList = props.feature["3dnetwork:troncon_list"] ? props.feature["3dnetwork:troncon_list"].filter((item: tronconInterface) => String(item["3dnetwork:folder_name"]) === String(meshFilePathToArray[2])) : ""
                    let flow: string | undefined;
                    let regardDestination: string | undefined;
                    // Transformation du type
                    let type = formatSigType(props.feature["sig:couche"]);


                    if (!props.feature["index:name"].toUpperCase().includes("TRONCON")) {
                        interface flowDirection {
                            fromTo: string;
                            flow: string;
                        }

                        interface flowDirectionArray {
                            [key: string]: string;
                        }

                        let associativeArray: flowDirectionArray = Array.isArray(props.feature?.["3dnetwork:flowDirection"])
                            ? props.feature["3dnetwork:flowDirection"].reduce((acc: flowDirectionArray, obj: flowDirection) => {
                                if (obj && obj.fromTo !== undefined && obj.flow !== undefined) {
                                    acc[obj.fromTo] = obj.flow;
                                }
                                return acc;
                            }, {})
                            : {}; // Retourne un objet vide si la propriété est absente

                        // Vérification et attribution de la valeur de flow
                        flow = Object.keys(associativeArray).length === 0
                            ? "AMONT"
                            : associativeArray[String(meshFilePathToArray[2])] || "AMONT";


                        regardDestination = parseInt(regardAmontAval[0]) != parseInt(getValidNum(props.feature["asset:nomexplo"], props.feature["asset:id_sig"]))
                            ? regardAmontAval[0]
                            : regardAmontAval[1];


                    }

                    const nomExplo: string = getValidNum(
                        props.feature["asset:nomexplo"],
                        props.feature["asset:id_sig"]
                    ) ?? "";

                    const fileName: string | undefined = meshFilePathToArray[3]; // Vérification de l'index
                    if (!fileName || !fileName.includes('.scene')) {
                        console.error("Format de fichier invalide :", fileName);
                        return "REGARD";
                    }

                    const baseName = fileName.replace('.scene', '');
                    const [firstPart, secondPart] = baseName.split('-'); // Sépare les deux identifiants


                    const extractIdAndType = (part: string): [string, string | null] => {
                        const splitPart = part.split('_');
                        return splitPart.length === 2 ? [splitPart[0], splitPart[1]] : [splitPart[0], null];
                    };

                    const [id1, type1] = extractIdAndType(firstPart);
                    const [id2, type2] = secondPart ? extractIdAndType(secondPart) : [null, null];

                    let otherType: string;
                    if (nomExplo === id1) {
                        otherType = type2 ?? "REGARD";
                    } else if (nomExplo === id2) {
                        otherType = type1 ?? "REGARD";
                    } else {
                        console.error("nomExplo non trouvé dans le fichier :", nomExplo);
                        otherType = "REGARD";
                    }

                    const TitleforRegard: string = flow
                        ? (String(flow) === 'AMONT'
                                ? `${otherType} ${regardDestination} vers ${type} ${nomExplo}`
                                : `${type} ${nomExplo} vers ${otherType} ${regardDestination}`
                        )
                        : "";

                    return (
                        <Accordion defaultActiveKey="0">
                            <Card className="container-bordered">

                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={`${index}`}
                                    onClick={() => meshOpen === index ? setMeshOpen(-1) : setMeshOpen(index)}
                                >
                                    {
                                        (props.feature["index:name"].toUpperCase().includes("TRONCON"))
                                            ?
                                            <b><span>{`Regard ${firstRegard} vers Regard ${secondRegard}`}</span></b>
                                            :
                                            <b><span>{TitleforRegard}</span></b>
                                    }

                                    {meshOpen === index ?
                                        <SzIcon variant="bold" icon="arrow-up-1"/> :
                                        <SzIcon variant="bold" icon="arrow-down-1"/>
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`${index}`}>
                                    <div className="work3d">
                                        <ul>
                                            <li className={`gaia-search-data__item`}>
                                            <span
                                                className="gaia-search-data__item-content">{t("asset:3d.model3d")} {meshFilePathToArray[2]}</span>
                                                <div
                                                    className="gaia-search-data__item-links">
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{show: 100, hide: 200}}
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                <Trans>visualiser</Trans>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i
                                                            className="gaia-search-data__item-links-icon material-icons clickable material-icons-24px"
                                                            onClick={() => showIfc(meshListFiled, tronconList)}
                                                        >remove_red_eye</i>
                                                    </OverlayTrigger>
                                                    <br></br>
                                                </div>
                                                {
                                                    tronconList && tronconList.map((troncon: any, index: any) => {
                                                        return (

                                                            <ul>
                                                                <li>
                                                                <span className="gaia-search-data__item-content-title">
                                                                    {
                                                                        <Trans>{troncon["3dnetwork:troncon:index:name"]}</Trans>} {troncon["3dnetwork:troncon:asset:id_sig"]}
                                                                </span>
                                                                    <br></br>
                                                                    <em className="gaia-search-data__item-content-more"> {troncon["3dnetwork:troncon:sig:adressecomplete"]}</em>
                                                                </li>
                                                                <br></br>
                                                                <br></br>
                                                            </ul>

                                                        )
                                                    })
                                                }

                                            </li>
                                        </ul>
                                    </div>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    )
                })}
            </>
        );


    }

    if (ppv3dExist == true && meshExist == true)
        return (
            <div>
                {meshDataBlock}
                {ppv3dDataBlock}
            </div>
        );
    else if (ppv3dExist == true && meshExist == false)
        return (
            <div>
                {ppv3dDataBlock}
            </div>
        );
    if (ppv3dExist == false && meshExist == true)
        return (
            <div>
                {meshDataBlock}
            </div>
        );
    else return <DataNotReady/>;
};

const mapStateToProps = (state: { search: ISearch }) => {
    return {
        shownEquipementId: state.search.detail.shownEquipementId,
        search: state.search,
        showSmartshape: state.search.flag.showSmartshape

    };
};


const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(SearchActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Work3d);
