import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzModal } from "react-theme-components";
import { bindActionCreators } from "redux";
import { ErrorActions, SearchActions } from "../../../store/redux/actions";
import "./errorMessage.scss";

// Définition du type des props
interface ErrorMessageProps {
    show?: boolean;
    title?: string;
    message?: string;
    closeButtonText?: string;
    resetErrorMessage?: () => void;
    displaySmartshapeSessionError?: (payload: { smartshapeSessionError: boolean }) => void;
}

const ErrorMessageDynamicMessage: React.FC<ErrorMessageProps> = ({
                                                                     show = false,
                                                                     title,
                                                                     message,
                                                                     closeButtonText,
                                                                     resetErrorMessage,
                                                                     displaySmartshapeSessionError
                                                                 }) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState<boolean>(show);

    useEffect(() => {
        setIsVisible(show);
    }, [show]);

    const handleClose = () => {
        setIsVisible(false);
        if (displaySmartshapeSessionError) {
            displaySmartshapeSessionError({ smartshapeSessionError: false });
        }
        resetErrorMessage?.();
    };

    return (
        <SzModal
            title={title || t("error.title")}
            show={isVisible}
            handleClose={handleClose}
        >
            <p>{message || t("error.text", "Error, please contact administrator")}</p>
        </SzModal>
    );
};

// Mapping du state Redux aux props du composant
const mapStateToProps = (state: any) => {
    return {
        smartshapeSessionError: state.search.flag.smartshapeSessionError,
    };
};

// Connexion Redux avec dispatch des actions
const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators({
        ...ErrorActions,
        ...SearchActions,
        displaySmartshapeSessionError: (payload: { smartshapeSessionError: boolean }) =>
            dispatch(SearchActions.displaySmartshapeSessionError(payload))
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageDynamicMessage);
